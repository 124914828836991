<template>
  <div>
    <van-nav-bar
        v-if="!isInMini()"
        id="nav-bar"
        title="支付成功"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
    />
    <van-image :src="require('@/assets/zfcg_icon.png')" width="85" height="90" style="margin-top: 45px; margin-left: 50%; transform: translate(-50%, 0)" />
    <div style="display: flex; justify-content: space-between; margin-top: 20px">
      <div class="title">订单号</div>
      <div class="value">{{orderSn}}</div>
    </div>
    <div class="line-line" />
    <div style="display: flex; justify-content: space-between;">
      <div class="title" style="min-width: 60px">格子号</div>
      <div class="value">{{cellNoText}}</div>
    </div>
    <div class="line-line" />
    <div style="display: flex; justify-content: space-between;">
      <div class="title">取餐码</div>
      <div class="value">{{takeCode}}</div>
    </div>
    <div class="line-line" />
    <div v-if="payResult.orderType === 'CUSTOM'" class="title">短信通知内容</div>
    <div v-if="payResult.orderType === 'CUSTOM'" class="value">{{ smsText }}</div>
    <div v-if="payResult.orderType === 'CUSTOM'" @click="clickSentSms" style="width: 195px; height: 33px; color: white;background: #FFBD04; border-radius: 4px; line-height: 33px; text-align: center; margin-left: 50%; transform: translate(-50%, 0); margin-bottom: 20px">点击复制短信，通知顾客</div>
    <div v-if="payResult.orderType === 'CUSTOM'" class="line-line" />

    <div v-if="showReopen" style="display: flex; flex-direction: row; margin: 30px 15px; align-items: center">
      <van-image :src="require('@/assets/pay/bangzhu_icon.png')" width="15" height="15"/>
      <span style="color: #999999; font-size: 14px; margin-left: 6px">柜门未开启？请点击</span>
      <div @click="reopenCell" style="border: solid 1px #FF6204; color: #FF6204; font-size: 15px; font-weight: bold; padding: 4px 6px; border-radius: 4px; margin-left: 8px">再次开门</div>
    </div>

    <div class="button" @click="backToHome">返回首页</div>
  </div>
</template>

<script>
import Client from "@/api";

const TWO_MINUTES = 2 * 60 * 1000

export default {
  name: "AreaList",
  data: function () {
    let username = localStorage.getItem('username')
    let payResult = JSON.parse(localStorage.getItem('payResult'))
    let createTime = Date.parse(payResult.createTime)
    let showReopen = (Date.now() - createTime) < TWO_MINUTES
    return {
      orderSn: '',
      cellNoText: '',
      takeCode: '',
      username,
      payResult,
      mobileTail: payResult.mobileTail,
      showReopen: showReopen,
      smsText: ''
    }
  },
  created() {
    setTimeout(() => {
      this.showReopen = false
    }, TWO_MINUTES)
  },
  mounted() {
    let order = JSON.parse(localStorage.getItem('order'))
    this.orderSn = order.orderSn
    this.takeCode = order.takeCode

    let cabinet = JSON.parse(localStorage.getItem('cabinet'))
    let cellList = JSON.parse(localStorage.getItem("cellList"))
    this.cellNoText = cellList.map(cell => { return cabinet.lockerName + cell.compartmentName }).join('、')
    this.smsText = `【裕登小馋猫】您的餐品已存入${this.cellNoText}，取餐码${this.takeCode}一小时内有效，请您尽快取餐！送餐人${this.username}`
  },
  methods: {
    backToHome() {
      // eslint-disable-next-line no-undef
      wx.miniProgram.reLaunch({url: '/pages/index/index'})
    },
    reopenCell() {
      this.$toast.loading({duration: -1})
      Client.post('/courier/reopenCell', JSON.parse(localStorage.getItem('order')).courierOrderId, {headers: {'Content-Type': 'application/json'}}).then(res => {
        this.$toast.clear()
        if (res.data.state === 'ok') {
          this.$toast.success('指令已发送');
        } else {
          this.$toast.success(res.data.msg);
        }
      }).catch(err => {
        this.$toast.success(err.response.data.msg);
      })
    },
    clickSentSms() {
      // let sms = `【裕登小馋猫】您的餐品已存入${this.cellNoText}，取餐码为${this.takeCode}，按学校规定，取餐码有效期为1小时，请尽快取餐！送餐人${this.username}`
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({url: `/pages/clipboard/clipboard?text=${this.smsText}`})
      /*if (navigator.clipboard) {
        try {
          navigator.clipboard.writeText(sms)
          this.$toast.success('已复制')
        } catch (e) {
          console.log(e)
          this.$toast.fail('复制失败')
        }
      } else {
        this.$toast.fail('复制失败')
      }*/
      // window.location.href = 'sms:' + this.mobileTail + '&body=' + sms
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 19px;
  margin-left: 15px;
  margin-top: 15px;
}
.value {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 19px;
  margin: 15px;
}
.line-line {
  margin-left: 15px;
  margin-right: 15px;
  height: 1px;
  background: #EFEFEFDD;
}
.button {
  margin: 80px 15px;
  height: 48px;
  background: #FFBD04;
  border-radius: 24px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
